import {
  Routes,
  Route,
  Navigate,
  RouteProps,
  RoutesProps,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import AddServiceRequest from "../components/Maintenance/AddServiceRequest";
import CompoLoader from "../components/Common/ComponentLoader/CompoLoader";
import Maintenance from "../components/Maintenance/Maintenance";
import ReceiptDetail from "../components/Accounting/TabReceipt/ReceiptDetail";
import SelectOrganization from "../components/Header/SelectOrganization";

const Navigation = () => {

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const USER_MODE = localStorage.getItem("user_mode");

  useEffect(() => {
    if (access_token === null) {
      navigate("/login");
    }
  }, [access_token]);
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const NotFound = React.lazy(() => import("../components/NotFound/notfound"));
  // const CreateAccount = React.lazy(() => import("../components/Login/CreateAccount"));
  const CreateAccount = React.lazy(
    () => import("../components/Login/CreateAccount/CreateAccount")
  );
  const Login = React.lazy(() => import("../components/Login/LoginOption"));
  const PhoneLogin = React.lazy(
    () => import("../components/Login/Login/PhoneNumber")
  );
  const EmailLogin = React.lazy(
    () => import("../components/Login/Login/EmailLogin")
  );

  const SelectAccountType = React.lazy(
    () => import("../components/Login/Login/SelectAccountType_1")
  );

  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const PasswordOtp = React.lazy(
    () => import("../components/Login/PasswordOtp")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );

  const PropertiesList = React.lazy(
    () => import("../components/Properties/List/PropertiesList")
  );

  //Add Property - Single Unit
  const SeletPropertyType = React.lazy(
    () => import("../components/Properties/AddProperty/SelectPropertyType")
  );
  const AddSingleUnitProperty = React.lazy(
    () =>
      import(
        "../components/Properties/AddProperty/SingleUnit/AddSingleUnitProperty"
      )
  );
  const AddMultiUnitProperty = React.lazy(
    () =>
      import(
        "../components/Properties/AddProperty/MultiUnit/AddMultiUnitProperty"
      )
  );

  //propertydetails
  const PropertyDetails = React.lazy(
    () =>
      import(
        "../components/Properties/PropertyDetail/PropertyInfo/PropertyInfo"
      )
  );

  //propertydetails
  const BedDetails = React.lazy(
    () =>
      import("../components/Properties/PropertyDetail/BedDetails/BedDetails")
  );
  const UnitDetails = React.lazy(
    () => import("../components/Properties/PropertyDetail/UnitInfo/UnitInfo")
  );

  const RentDetails = React.lazy(
    () =>
      import("../components/Properties/PropertyDetail/BookingDetails/BookingDetails")
  );
  const ServiceRequest = React.lazy(
    () => import("../components/Maintenance/AddServiceRequest")
  );
  const MaintenanceDetails = React.lazy(
    () => import("../components/Maintenance/MaintenanceDtails")
  );

  //propertyBooking
  const PropertBooking = React.lazy(
    () => import("../components/Properties/BookProperty/BookProperty")
  );
  const CancelPropertyBooking = React.lazy(
    () => import("../components/Properties/CancelBooking/CancelBooking")
  );

  //tenant
  const Tenants = React.lazy(
    () => import("../components/Tenants/List/TenantsList")
  );
  const AddTenants = React.lazy(
    () => import("../components/Tenants/AddTenant/AddTenant")
  );
  const TenantDetails = React.lazy(
    () => import("../components/Tenants/TenantDetails/TenantDetails")
  );

  //My Profile
  const ProfileDetail = React.lazy(
    () => import("../components/Settings/Profile/ProfileDetail")
  );
  const Profile = React.lazy(
    () => import("../components/Settings/Profile/ProfileSetting")
  );

  //mobile pages
  const PageHeaderSearch = React.lazy(
    () => import("../components/MobileSearchPage")
  );
  const MobileNotifications = React.lazy(
    () => import("../components/MobileNotifications")
  );
  const MobileProfileMenu = React.lazy(
    () => import("../components/Header/MobileProfileDrpdonnPage")
  );

  // employees

  const Employees = React.lazy(
    () => import("../components/Employee/EmployeeList")
  );
  const ReviewEmployee = React.lazy(
    () => import("../components/Employee/ReviewEmployee")
  );

  const EmployeesAddEdit = React.lazy(
    () => import("../components/Employee/AddEmpBasicDetail")
  );
  const EmployeesProfessionAddEdit = React.lazy(
    () => import("../components/Employee/AddEmpJobDetail")
  );
  const EmployeeReviewPage = React.lazy(
    () => import("../components/Employee/EmployeeDetails")
  );
  const TerminateEmployee = React.lazy(
    () => import("../components/Employee/TerminateEmp")
  );

  // Vendor
  const Vendors = React.lazy(() => import("../components/Vendors/Vendors"));
  const AddEditVendor = React.lazy(
    () => import("../components/Vendors/AddVendorDetail")
  );
  const VendorAddEditDetails = React.lazy(
    () => import("../components/Vendors/AddEditVendorDetail")
  );
  const VendorUploadDocument = React.lazy(
    () => import("../components/Vendors/UploadedVendorDoc")
  );
  const VendorReviewDetail = React.lazy(
    () => import("../components/Vendors/ReviewVendor")
  );
  const ViewVendorDetail = React.lazy(
    () => import("../components/Vendors/VendorDetails")
  );

  // Work Order

  const AddWorkOrder = React.lazy(
    () => import("../components/Vendors/AddWorkOrderDetail")
  );
  const ViewWorkOrder = React.lazy(
    () => import("../components/Vendors/WorkOrderDetails")
  );

  // Purchase Order

  const AddPurchaseOrder = React.lazy(
    () => import("../components/Vendors/AddPurchaseOrderDetail")
  );
  const ViewPurchaseOrder = React.lazy(
    () => import("../components/Vendors/purchaseOrderDetails")
  );

  // Leads

  const Leads = React.lazy(() => import("../components/Leads/Leads"));

  const AddEditLead = React.lazy(
    () => import("../components/Leads/AddLeadDetails")
  );
  const VendorAddEditRequirement = React.lazy(
    () => import("../components/Leads/AddReqDetails")
  );

  const ReviewLead = React.lazy(() => import("../components/Leads/ReviewLead"));

  const ViewLeadDetail = React.lazy(
    () => import("../components/Leads/LeadDtails")
  );

  // Accounting Module

  const Accounting = React.lazy(
    () => import("../components/Accounting/Accounting")
  );
  const AddEditInvoice = React.lazy(
    () => import("../components/Accounting/TabInvoice/SelectInvoiceType")
  );
  const AccountDetails = React.lazy(
    () => import("../components/Accounting/TabOverview/AccTransactionDetail")
  );
  const AddEditDefaultInvoice = React.lazy(
    () => import("../components/Accounting/TabInvoice/AddDefaultInvoice")
  );
  const AddEditCustomInvoice = React.lazy(
    () => import("../components/Accounting/TabInvoice/AddCustomInvoice")
  );

  const ViewInvoiceDetail = React.lazy(
    () => import("../components/Accounting/TabInvoice/AccInvoiceDetail")
  );

  const AddInvoicePayment = React.lazy(
    () => import("../components/Accounting/TabInvoice/AddPaymentDetails")
  );
  const AddAccountExpenses_1 = React.lazy(
    () => import("../components/Accounting/TabExpenses/AddExpenses")
  );
  const AddAccountExpenses_2 = React.lazy(
    () => import("../components/Accounting/TabExpenses/AddExpenses2")
  );
  const ExpenseDetails = React.lazy(
    () => import("../components/Accounting/TabExpenses/ExpensesDetails")
  );

  const AddPaymentDetails = React.lazy(
    () => import("../components/Accounting/TabExpenses/AddPaymentDetails")
  );

  const AddReceipt = React.lazy(
    () => import("../components/Accounting/TabReceipt/AddReceipt")
  );
  const receiptDetails = React.lazy(
    () => import("../components/Accounting/TabReceipt/ReceiptDetail")
  );
  const Settings = React.lazy(() => import("../components/Settings/Settings"));
  const Biiling_Payment = React.lazy(
    () => import("../components/Settings/Billing/BillingPayment")
  );

  const NotificationSettings = React.lazy(
    () => import("../components/Settings/NotificationSettings")
  );
  // Access Control
  const AccessControl = React.lazy(
    () => import("../components/Settings/AccessControl/accessControl")
  );


  // File
  const FileManager = React.lazy(
    () => import("../components/Settings/FileManager/fileManager")
  );

 ;
  const SubscriptionRequestSent = React.lazy(
    () => import("../components/Login/Subscription/Plan_Request_Sent")
  );


  const AdminEmployeeAccessControl = React.lazy(
    () => import("../components/Admin/AccessControl/accessControl")
  );


  const AdminEmployees = React.lazy(
    () => import("../components/Admin/Employee/EmployeeList")
  );
  const AddAdminEmployees = React.lazy(
    () => import("../components/Admin/Employee/AddEditAdminEmployee")
  );

  const EditAdminEmployees = React.lazy(
    () => import("../components/Admin/Employee/AddEditAdminEmployee")
  );
  const ViewAdminEmployees = React.lazy(
    () => import("../components/Admin/Employee/EmployeeDetails")
  );

  const SubscriptionPlanWed = React.lazy(
    () => import("../components/Login/Subscription/Plans")
  );

  const SubscriptionPlanAdmin = React.lazy(
    () => import("../components/Admin/SubscriptionPlan/planList")
  );
  const ViewSubscriptionPlanAdmin = React.lazy(
    () => import("../components/Admin/SubscriptionPlan/viewPlan")
  );
  const AddEditSubscriptionPlan = React.lazy(
    () => import("../components/Admin/SubscriptionPlan/AddEditPlan")
  );
  const SubscriberLists = React.lazy(
    () => import("../components/Admin/Subscribers/subscribers")
  );
  const RequestLists = React.lazy(
    () => import("../components/Admin/Payments/request")
  );

  const DashboardAdmin = React.lazy(
    () => import("../components/Admin/Dashboard/index")
  );

  const ViewSubscriberAdmin = React.lazy(
    () => import("../components/Admin/Subscribers/subscriberdetail")
  );

  const AdminProfile = React.lazy(
    () => import("../components/Admin/ProfileSetting")
  );


  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<CompoLoader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/login/phone"
            element={
              <Suspense fallback={<CompoLoader />}>
                {" "}
                <PhoneLogin />
              </Suspense>
            }
          />
          <Route
            path="/login/email"
            element={
              <Suspense fallback={<CompoLoader />}>
                <EmailLogin />
              </Suspense>
            }
          />


          {(USER_MODE !== "SUPER_ADMIN" && USER_MODE !== "SUPER_ADMIN_EMPLOYEE") ? (

            <>
              <Route
                path="/accounting/expense/add-payment-details/:expense_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddPaymentDetails />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/select-userType"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <SelectAccountType />
                  </Suspense>
                }
              />
              <Route
                path="/subscription/plans"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <SubscriptionPlanWed />
                  </Suspense>
                }
              />
              <Route
                path="/subscription/plans/request-sent"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <SubscriptionRequestSent />
                  </Suspense>
                }
              />
              <Route
                path="/create-account/:type"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <CreateAccount />
                  </Suspense>
                }
              />

              <Route
                path="/forgot-password/:type"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    {" "}
                    <ForgotPassword />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/password-otp"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <PasswordOtp />
                  </Suspense>
                }
              />

              <Route
                path="/add-employee"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <EmployeesAddEdit />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/edit-employee/:uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <EmployeesAddEdit />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-employee-profession/:employee_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <EmployeesProfessionAddEdit />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-employee-profession/:employee_uuid/update/:profession_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <EmployeesProfessionAddEdit />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/employee-review/:uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <ReviewEmployee />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-vendor"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditVendor />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/edit-vendor/:uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditVendor />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-vendor-detail/:vendor_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorAddEditDetails />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-vendor-detail/:vendor_uuid/update/:detail_id"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorAddEditDetails />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/upload-vendor-document/:vendor_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorUploadDocument />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/review-vendor/:vendor_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorReviewDetail />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-work-order"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddWorkOrder />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/edit-work-order/:work_order_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddWorkOrder />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-purchase-order"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddPurchaseOrder />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-purchase-order/:purchase_order_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddPurchaseOrder />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-lead"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditLead />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-lead/:lead_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditLead />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-lead-requirement/:lead_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorAddEditRequirement />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-lead-requirement/:lead_uuid/update/:req_id"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <VendorAddEditRequirement />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/review-lead/:lead_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <ReviewLead />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-invoice"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditInvoice />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-invoice/:invoice_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditInvoice />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-invoice-default"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditDefaultInvoice />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-invoice-default/:invoice_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditDefaultInvoice />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-invoice-custom"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditCustomInvoice />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-invoice-custom/:invoice_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddEditCustomInvoice />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-payment-invoice/:invoice_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddInvoicePayment />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-payment-invoice/:invoice_uuid/payment/:payment_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddInvoicePayment />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/accounting/add-expenses_1"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddAccountExpenses_1 />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/accounting/add-expenses_2"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddAccountExpenses_2 />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/accounting/edit-expenses_1/:expense_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddAccountExpenses_1 />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/accounting/edit-expenses_2/:expense_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddAccountExpenses_2 />{" "}
                  </Suspense>
                }
              />
              {/* tab receipt */}
              <Route
                path="/accounting/add-receipt"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddReceipt />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/accounting/edit-receipt/:receipt_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddReceipt />{" "}
                  </Suspense>
                }
              />

              {/* Add Property  - Single Unit  */}
              <Route
                path="/add-property/select-property-type"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    {" "}
                    <SeletPropertyType />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-property/single-unit"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddSingleUnitProperty />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-property/single-unit"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddSingleUnitProperty />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-property-unit-details"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddSingleUnitProperty />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-property/multi-unit"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddMultiUnitProperty />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-service-request"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddServiceRequest />{" "}
                  </Suspense>
                }
              />
               <Route
                path="/edit-service-request/:request_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddServiceRequest />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/add-property/multi-unit"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddMultiUnitProperty />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/add-tenant"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddTenants />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/edit-tenant"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddTenants />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/property-booking"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <PropertBooking />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/edit-property-booking"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <PropertBooking />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/cancel-property-booking/:booking_id"
                element={
                  <Suspense fallback={<CompoLoader />}>
                 <CancelPropertyBooking/>
                  </Suspense>
                }
              />

              <Route
                path="/terminate-employee/:employee_uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <TerminateEmployee />{" "}
                  </Suspense>
                }
              />

              {/* Navigation  Inner  pages  */}
              <Route
                path="/"
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Main />}
                  />
                }
              >
                <Route
                  path="*"
                  element={<Suspense fallback={<CompoLoader />}>
                    <NotFound />
                  </Suspense>}
                />
                <Route
                  path="/properties"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      {" "}
                      <PropertiesList />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/select-organization"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      {" "}
                      <SelectOrganization />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      {" "}
                      <Dashboard />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/tenants"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Tenants />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/property-details"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <PropertyDetails />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/unit-details"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <UnitDetails />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/bed-details"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <BedDetails />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/maintenance-details/:uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <MaintenanceDetails />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/tenants-details/:tenant_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <TenantDetails />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/accounting/expenses/details/:expense_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ExpenseDetails />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/rent-details"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <RentDetails />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/employees"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Employees />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/admin-employees"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AdminEmployees />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/access-controls"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AccessControl />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/file-managers"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <FileManager />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/vendors"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Vendors />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/leads"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Leads />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/maintenance"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Maintenance />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/employee-review/:employee_uuid/professional/:profession_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <EmployeeReviewPage />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/employee-review/:employee_uuid/professional/:profession_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <EmployeeReviewPage />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/employee-review/:employee_uuid/professional/:profession_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <EmployeeReviewPage />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/terminate-employee/:employee_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <TerminateEmployee />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-vendor-details/:vendor_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewVendorDetail />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-lead-details/:lead_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewLeadDetail />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-overview-details/:payment_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AccountDetails />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/accounting"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Accounting />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/accounting/receipt-details/:receipt_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ReceiptDetail />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/view-invoice-details/:invoice_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewInvoiceDetail />{" "}
                    </Suspense>
                  }
                />
                {/* 
            <Route
              path="/view-vendor-details/:vendor_uuid"
              element={
                <Suspense fallback={<CompoLoader />}>
                  <ViewVendorDetail />{" "}
                </Suspense>
              }
            /> */}
                <Route
                  path="/view-work-order/:work_order_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewWorkOrder />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-purchase-order/:purchase_order_uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewPurchaseOrder />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/profile-setting"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Profile />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/profile-details"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ProfileDetail />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/billing-payment"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <Biiling_Payment />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/notification-settings"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <NotificationSettings />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      {" "}
                      <Settings />{" "}
                    </Suspense>
                  }
                />
              </Route>

            </>
          ) : (
            <>
              
              <Route
                path="/add-employee"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <AddAdminEmployees />{" "}
                  </Suspense>
                }
              />

              <Route
                path="/edit-employee/:uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <EditAdminEmployees />{" "}
                  </Suspense>
                }
              />
            
              
              <Route
                path="/"
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Main />}
                  />
                }
              >
                <Route
                  path="*"
                  element={<Suspense fallback={<CompoLoader />}>
                    <NotFound />
                  </Suspense>}
                />

<Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <DashboardAdmin />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/admin-employees"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AdminEmployees />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/roles"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AdminEmployeeAccessControl />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/subscription_plans"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <SubscriptionPlanAdmin />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-subscription-plan/:uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewSubscriptionPlanAdmin />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/add-subscription-plan"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AddEditSubscriptionPlan />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/edit-subscription-plan/:uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AddEditSubscriptionPlan />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/subscribers"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <SubscriberLists />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/view-subscriber-detail/:uuid"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <ViewSubscriberAdmin />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/requests"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <RequestLists />{" "}
                    </Suspense>
                  }
                />
                <Route
                  path="/profile-setting"
                  element={
                    <Suspense fallback={<CompoLoader />}>
                      <AdminProfile />{" "}
                    </Suspense>
                  }
                />
                  <Route
                path="/view-employee/:uuid"
                element={
                  <Suspense fallback={<CompoLoader />}>
                    <ViewAdminEmployees />{" "}
                  </Suspense>
                }
              />

                {/* rest of the routes will come here upper before this Comment */}
              </Route>
              </>

          )}
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
