import * as React from "react";
export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: React.ReactElement; // Corrected type from `React.Element`
};
export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  return outlet;
}
