import { Link } from "react-router-dom";

import { ImagePath } from "../../assets/images/menu-icon/helper";

import { toast } from "react-toastify";

import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";

import { Placeholder } from "react-bootstrap";

interface PropData {
  isToggle: any;
}

const VerticalMenu = (props: PropData) => {
  const [menus, SetMenus] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const[activeMenu,setActiveMenu]=useState(localStorage.getItem("active_menu")??"Dashboard")
  useEffect(() => {
    GetDynamicMenus();
  }, []);

  const GetDynamicMenus = () => {
    setLoader(true);
    return WebService.CommonApi({
      method: "GET",
      action: `left-menus`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          SetMenus(res.list);
        }
        setLoader(false);
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        setLoader(false);
        return error;
      });
  };

  return (
    <>
      <div
        id="vertical_menu"
        className={`verticle-menu ${props.isToggle ? "open-menu" : ""}`}
      >
        <div className="menu-list position-relative">
          {/* {loader ? <CompoLoader defaultHeight={'500px'} /> : ""} */}
          {loader ? (
            <div className="px-3 menu-placeholder">
              {[...Array(10)].map((_, index) => (
                <Placeholder key={index} xs={12} />
              ))}
            </div>
          ) : (
            ""
          )}
          {menus?.length > 0
            ? menus.map(function (menu: any, index: any) {
                return (
                  <Link
                    key={index}
                    id="t-1"
                    to={menu?.url}
                    onClick={() => {localStorage.setItem("property_detail_tab","tab1")
                      localStorage.setItem("tabId", "tab1");
                      setActiveMenu(menu?.name)
                      localStorage.setItem("active_menu",menu?.name)
                    }}
                    className={activeMenu===menu?.name? 'menu-item active':'menu-item'}
                  >
                    {" "}
                    <img
                      src={ImagePath(menu?.icon_class)}
                      className="menu-icon"
                    />{" "}
                    <span className="nav-text">{menu?.name}</span>
                  </Link>
                );
              })
            : ""}
        
        </div>
      </div>
    </>
  );
};
export default VerticalMenu;
