import { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PropsData {
  totalCount: number;
  itemCountPerPage: number;
  changePage: (page: number) => void;
}

const RentCityPagination = ({
  totalCount,
  itemCountPerPage,
  changePage,
}: PropsData) => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    changePage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / itemCountPerPage));
  }, [totalCount, itemCountPerPage]);

  const maxVisiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(totalPage, startPage + maxVisiblePages - 1);

  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  return (
    <Pagination>
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(1)}
      />
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      />

      {/* {startPage > 1 && <Pagination.Ellipsis disabled />}  */}
      {[...Array(endPage - startPage + 1)].map((_, index) => (
        <Pagination.Item
          key={startPage + index}
          active={currentPage === startPage + index}
          onClick={() => setCurrentPage(startPage + index)}
        >
          {startPage + index}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={currentPage  === totalPage}
        onClick={() => setCurrentPage(currentPage + 1)}
      />
      <Pagination.Last
        disabled={currentPage  === totalPage}
        onClick={() => setCurrentPage(totalPage)}
      />
    </Pagination>
  );
};

export default RentCityPagination;
