import { useEffect, useState } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { GoImage } from "react-icons/go";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { validImageTypes } from "../../assets/constants/constants";
import { allowedImagefile } from "../../assets/constants/ErrorMessages";
import "../../assets/css/drag-drop-uploader.scss";
import InnerHeader from "../../components/InnerHeader";
interface PropsData {
  handleNext: any;
  handleBack: any;
  imgFiles: any;
}
const AddServiceResquest2 = (props: PropsData) => {
  const [files, setFiles] = useState<any>([]);
  useEffect(() => {}, []);
  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;

    
    let maxSizeFile = false;
    Array.from(selectedFiles).map((file: any) => {
      if (file.size > 10 * 1024 * 1024) {
        maxSizeFile = true;
        toast.error("Maximum image size 10 mb");
        return;
      }
      if (!validImageTypes.includes(file.type)) {
        maxSizeFile = true;
        toast.error(allowedImagefile);
        return;
      }
    });
    if (maxSizeFile) {
      return;
    }
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles).map((file: any) => ({
        file,
        localAddress: URL.createObjectURL(file),
        has_covered_image: 0,
        existing: false,
        id: "",
      }));
      props.handleNext(newFiles);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const selectedFiles = event.dataTransfer.files;
    let maxSizeFile = false;
    Array.from(selectedFiles).map((file: any) => {
      if (file.size > 10 * 1024 * 1024) {
        maxSizeFile = true;
        toast.error("Maximum image size 10 mb");
        return;
      }
    });
    if (maxSizeFile) {
      return;
    }

    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles).map((file: any) => ({
        file,
        localAddress: URL.createObjectURL(file),
        has_covered_image: 0,
        id: "",
      }));

      props.handleNext(newFiles);
    }
  };

  const handleNext = () => {
    if (props.imgFiles && props.imgFiles.length > 0) {
      props.handleNext(props.imgFiles);
    } else {
      props.handleNext();
    }
  };

  return (
    <>
      <div className="layout-veritcle-menu">
        <InnerHeader
          pageTitle={window.location.href.includes("edit-") ? "Edit Request" : "Add Request"}
          backArrow={false}
          closeBtn={true}
          closeUrl=""
          HelpBtn={true}
        />
        <div className="app-body no-left-menu pb-lg-0 pb-5 mb-4">
          <div className="app-page page-properties property-detail pb-3">
            <div className="form-style">
              <Row className="justify-content-center">
                <Col lg={8} className="mb-3">
                  <h2 className="font-18 font-semibold mb-4">Add Photos</h2>

                  <div className="drag-drop-uploader">
                    <div
                      className={`document-uploader ${
                        files.length > 0 ? "upload-box active" : "upload-box"
                      }`}
                      onDrop={handleDrop}
                      onDragOver={(event) => event.preventDefault()}
                    >
                      <input
                        type="file"
                        hidden
                        id="browse"
                        onChange={handleFileChange}
                        accept=".jpeg, .jpg,.png,.pdf,.docx,.pptx,.txt,.xlsx"
                        multiple
                      />
                      <div className="mb-3">
                        <GoImage size={32} className="text-brand" />
                      </div>
                      <h4 className="font-14 font-semibold">
                        Drag your photos to start uploading
                      </h4>
                      <p className="text-secondary-dark font-12">
                      Supported Type - JPG,JPEG,GIF,PNG | Maximum file size-10 MB
                      </p>
                      <label
                        htmlFor="browse"
                        className=" btn btn-outline-brand"
                      >
                        Select File to Upload
                      </label>
                    </div>
                    {/* 
                    {files.length > 0 && (
                      <div className="file-list">
                        <div className="file-list__container">
                          {files.map((file: any, index: number) => (
                            <div className="file-item" key={index}>
                              <div className="file-info text-center p-3">
                                <img src={file.localAddress} />
                              </div>
                            
                            </div>
                          ))}
                        </div>
                      </div>
                    )} */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="form-footer">
            <ProgressBar now={40} />
            <div className="footer-wrap">
              <div className="d-flex justify-content-between align-items-center">
                <div className="col-lg-6 col-3">
                  <Link
                    to="javascript:void(0)"
                    onClick={() => {
                      props.handleBack();
                    }}
                    className="text-nowrap"
                  >
                    <BiArrowBack size={16} className="me-1" />
                    Back
                  </Link>
                </div>
                <div className="col-lg-6 col-9 text-end d-flex gap-2 justify-content-end">
                  {/* <Button className="btn-outline-brand text-nowrap">
                    Save As Draft
                  </Button>{" "} */}
                  <Button
                    className="btn btn-brand"
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceResquest2;
