import { useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImage from "../../assets/images/default-avatar.jpg";
import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import CompoLoader from "../Common/ComponentLoader/CompoLoader";
import { Label } from "../Common/Label/Label";
import InnerHeader from "../InnerHeader";
interface ServiceRequestDetails {
  tenant_id: {
    value: string;
    label: string;
    type: string;
    booking_type: string;
  };
  property_id: {
    value: string;
    label: string;
    type: string;
    booking_type: string;
  };
  building_id: { value: string; label: string };
  unit_id: {
    value: string;
    label: string;
    booking_type: string;
    unit_id: string;
  };
  room_id: { value: string; label: string };
  bed_id: { value: string; label: string };
  category_id: {
    value: string;
    label: string;
    has_custom_value: number;
    custom_obj: CustomObject | null;
  };
  priority: string;
  narration: string;
}
interface CustomObject {
  title: string;
}

const priorityOptions = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
interface PropsData {
  handleNext: any;
  serviceRequestUUID: any;
}

const AddServiceResquest1 = (props: PropsData) => {
  const [tenantList, setTenantList] = useState<any[]>([]);
  const [tenantLoader, setTenantLoader] = useState(false);
  const [propertyOptionLoader, setPropertyOptionLoader] = useState(false);
  const [propertyKey, setPropetKey] = useState(Math.random());
  const [buildingKey, setBuildingKey] = useState(Math.random());
  const [unitKey, setUnitKey] = useState(Math.random());
  const [bedKey, setBedKey] = useState(Math.random());
  const [categortyLoader, setCategoryLoader] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [roomLoader, setRoomLoader] = useState(false);
  const [tenantKey, setTenantKey] = useState(Math.random());

  const [placeHolder, setplaceHolder] = useState("Select Category");
  const [autoFocus, setAutoFocus] = useState(false);
  const [searchable, setSearchable] = useState(false);
  const [key, setKey] = useState(Math.random());

  const [serviceRequestDetails, setServiceRequestDeatils] =
    useState<ServiceRequestDetails>({
      tenant_id: { value: "", label: "", type: "", booking_type: "" },
      property_id: { value: "", label: "", type: "", booking_type: "" },
      building_id: { value: "", label: "" },
      unit_id: { value: "", label: "", booking_type: "", unit_id: "" },
      room_id: { value: "", label: "" },
      bed_id: { value: "", label: "" },
      category_id: {
        value: "",
        label: "",
        has_custom_value: 0,
        custom_obj: null,
      },
      priority: "",
      narration: "",
    });
  const [propertyOptions, setPropertyOptions] = useState<any[]>([]);
  const [unitOptions, setUnitOptions] = useState<any[]>([]);
  const [roomOptions, setRoomOptions] = useState<any[]>([]);
  const [unitLoader, setUnitLaoder] = useState(false);
  const [buidingOptions, setBuildingOptions] = useState<any[]>([]);
  const [buildingLoader, setBuildingLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [bedOptions, setBedOptions] = useState<any[]>([]);
  const [bedroomLoader, setBedroomLoader] = useState(false);
  const [errFlag, setErrflag] = useState(false);
  let params = useParams();
  const [serviceUUID, setserviceUUID] = useState("");
  let location = useLocation();
  useEffect(() => {
    console.log("props?.serviceRequestUUID", props?.serviceRequestUUID);

    setserviceUUID(
      window.location.href.includes("edit-")
        ? params?.request_uuid
        : props?.serviceRequestUUID
    );
    setserviceUUID;
    if (props?.serviceRequestUUID || params?.request_uuid) {
      if (props?.serviceRequestUUID)
        getServiceRequestDetails(props?.serviceRequestUUID);
      else if (params?.request_uuid)
        getServiceRequestDetails(params?.request_uuid);
    }
  }, []);
  useEffect(() => {
    getCategories();
  }, []);
  const getServiceRequestDetails = (serviceUUID1: any) => {
    setPageLoader(true);
    return WebService.getAPI({
      action: `property/${serviceUUID1}/maintenance`,
    })
      .then((res: any) => {
        if (res?.info !== null) {
          getPropertyOptions(res?.info?.tenant?.id, res?.info?.raised_by);
          // getTenantList("",res?.info?.property?.id)
          if (res?.info?.property?.property_type === "SINGLE_UNIT") {
            getUnitDetails_SingleUnit(
              res?.info?.tenant?.id,
              res?.info?.property?.id
            );
            getroomOptions(
              res?.info?.tenant?.id,
              res?.info?.unit?.id,
              res?.info?.raised_by
            );

            getBedDetailsForSingle(
              res?.info?.tenant?.id,
              res?.info?.unit?.id,
              res?.info?.raised_by
            );
          } else if (res?.info?.property?.property_type === "MULTIPLE_UNIT") {
            getBuildingOptions(res?.info?.tenant?.id, res?.info?.property?.id);
            getUnitDetailsForMulti(
              res?.info?.tenant?.id,
              res?.info?.building?.id
            );
            getroomOptions(
              res?.info?.tenant?.id,
              res?.info?.unit?.id,
              res?.info?.raised_by
            );
            getBedDetailsForMulti(res?.info?.tenant?.id, res?.info?.unit?.id);
          }

          setTimeout(() => {
            setServiceRequestDeatils((prev: any) => ({
              ...prev,
              tenant_id: {
                value: res?.info?.tenant?.id,
                label: res?.info?.tenant?.name,
                type: res?.info?.raised_by,
                booking_type: res?.info?.property?.property_type,
              },
              property_id: {
                value: res?.info?.property?.id,
                label: res?.info?.property?.name,
                type: res?.info?.property?.property_type,
                booking_type: res?.info?.booking_type,
              },
              building_id: {
                value: res?.info?.building?.id,
                label: res?.info?.building?.building_name,
              },
              unit_id: {
                value:
                  res?.info?.property?.property_type === "SINGLE_UNIT"
                    ? res?.info?.floor_unit?.id
                    : res?.info?.unit?.id,
                label: res?.info?.floor_unit?.room_no,
                unit_id:
                  res?.info?.property?.property_type === "SINGLE_UNIT"
                    ? res?.info?.property?.unit?.id
                    : res?.info?.floor_unit_id,
              },
              room_id: {
                value: res?.info?.room?.id,
                label: res?.info?.room?.master_value?.title,
              },
              bed_id: {
                value: res?.info?.bed?.id,
                label: res?.info?.bed?.value,
              },
              category_id: {
                value: res?.info?.category?.id,
                label: res?.info?.category?.title,
              },
              narration: res?.info?.narration,
              priority: HelperService.toProperCase(res?.info?.priority),
            }));
          }, 1000);
        }
        setPageLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setPageLoader(false);
      });
  };
  const getCategories = () => {
    setCategoryLoader(true);
    return WebService.getAPI({
      action: `sub-category-list`,
    })
      .then((res: any) => {
        const updatedOptions = res.list.map((subCategory: any) => ({
          value: subCategory.id,
          label: subCategory.title,
          has_custom_value: 0,
          custom_obj: null,
        }));
        let obj = {
          value: 0,
          label: "+Add Custom",
          has_custom_value: 0,
          custom_obj: null,
        };
        updatedOptions.push(obj);
        setCategoryOptions(updatedOptions);
        setCategoryLoader(false);
      })
      .catch((error) => {
        setCategoryLoader(false);
        console.error("Error fetching data:", error);
      });
  };
  const getTenantList = (inputValue: any, property_id: any) => {
    console.log("property_id", property_id);

    let search = inputValue.trim();
    setTenantLoader(true);
    return WebService.getAPI({
      action: `search-tenants?type=MAINTENANCE_MODULE&keyword=${search}&property_id=${property_id}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          let updatedOptions = res.list.map((tenant: any) => ({
            value: tenant.id,
            label: tenant.name,
            profile: tenant.profile_image,
            address: tenant.address,
            type: tenant.type,
            property: tenant?.property,
            booking_type: tenant?.booking_type,
          }));
          setTenantList(updatedOptions);
          setTenantKey(Math.random());
        } else {
          setTenantList([]);
        }
        setTenantLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTenantList([]);
        setTenantLoader(false);
      });
  };
  const getAllTenantList = (inputValue: any) => {
    let search = inputValue.trim();
    setTenantLoader(true);
    return WebService.getAPI({
      action: `search-tenants?type=MAINTENANCE_MODULE&keyword=${search}`,
    }).then((res: any) => {
      if (res.list.length > 0) {
        let updatedOptions = res.list.map((tenant: any) => ({
          value: tenant.id,
          label: tenant.name,
          profile: tenant.profile_image,
          address: tenant.address,
          type: tenant.type,
          property: tenant?.property,
          booking_type: tenant?.booking_type,
        }));

        setTenantList(updatedOptions);
        setTenantKey(Math.random());
      } else {
        setTenantList([]);
      }
      setTenantLoader(false);
    });
  };
  const getPropertyOptions = (tenant_id: any, type: any) => {
    setPropertyOptionLoader(true);
    return WebService.getAPI({
      action: `property/${tenant_id}/search?type=${type}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((property: any) => ({
            value: property?.id,
            label: property?.name,
            type: property?.property_type,
            booking_type: property?.booking_type,
          }));
          setPropertyOptions(updatedOptions);
        } else {
          setPropertyOptions([]);
        }

        setPropertyOptionLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTenantList([]);
        setPropertyOptionLoader(false);
      });
  };
  const getBuildingOptions = (tenant_id: any, property_id: any) => {
    setBuildingLoader(true);
    return WebService.getAPI({
      action: `search/${tenant_id}/building-dropdown/${property_id}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((building: any) => ({
            value: building?.id,
            label: building?.building_name,
          }));
          setBuildingOptions(updatedOptions);
          if (res?.list?.length === 1) {
            setServiceRequestDeatils((prev: any) => ({
              ...prev,
              building_id: {
                value: res?.list[0]?.id,
                label: res?.list[0]?.building_name,
              },
            }));
          }
        } else {
          setBuildingOptions([]);
        }

        setBuildingLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBuildingOptions([]);
        setBuildingLoader(false);
      });
  };
  const getUnitDetails_SingleUnit = (tenant_id: any, property_id: any) => {
    console.log(serviceRequestDetails, "serviceRequestDetails");
    if (serviceRequestDetails?.tenant_id?.type) {
      setUnitLaoder(true);
      return WebService.getAPI({
        action: `search/${tenant_id}/unit-dropdown/${property_id}?type=${serviceRequestDetails?.tenant_id?.type}`,
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            const updatedOptions = res.list.map((unit: any) => ({
              value: unit?.id,
              label: unit?.master_value?.title,
              booking_type: unit?.booking_type,
              unit_id: unit?.unit_id,
            }));
            setUnitOptions(updatedOptions);

            setServiceRequestDeatils((prev: any) => ({
              ...prev,
              unit_id: {
                value: res?.list[0]?.id,
                label: res?.list[0]?.master_value?.title,
                unit_id: res?.list[0]?.unit_id,
              },
            }));
            getroomOptions(
              serviceRequestDetails?.tenant_id?.value,
              res?.list[0]?.id,
              serviceRequestDetails?.tenant_id?.type
            );
          } else {
            setUnitOptions([]);
          }
          setUnitLaoder(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setBuildingOptions([]);
          setUnitLaoder(false);
        });
    }
  };
  const getroomOptions = (tenant_id: any, unit_id: any, tenant_type: any) => {
    setRoomLoader(true);
    return WebService.getAPI({
      action: `search/${tenant_id}/room-dropdown/${unit_id}?type=${tenant_type}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((unit: any) => ({
            value: unit?.id,
            label: unit?.master_value?.title,
            booking_type: unit?.booking_type,
            unit_id: unit?.unit_id,
          }));
          setRoomOptions(updatedOptions);
        } else {
          setRoomOptions([]);
        }
        setRoomLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBuildingOptions([]);
        setUnitLaoder(false);
      });
  };
  const getUnitDetailsForMulti = (tenant_id: any, building_id: any) => {
    setUnitLaoder(true);
    return WebService.getAPI({
      action: `search/${tenant_id}/building/${building_id}/floor?type=${serviceRequestDetails?.tenant_id?.type}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((unit: any) => ({
            value: unit?.id,
            label: unit?.room_no,
            unit_id: unit?.unit_id,
          }));
          setUnitOptions(updatedOptions);
          // if (res?.list?.length === 1) {
          //   setServiceRequestDeatils((prev: any) => ({
          //     ...prev,
          //     unit_id: {
          //       value: res?.list[0]?.id,
          //       label: res?.list[0]?.room_no,
          //       unit_id: res?.list[0]?.unit_id,
          //       booking_type: res?.list[0]?.booking_type,
          //     },
          //   }));
          // }
        } else {
          setUnitOptions([]);
        }
        setUnitLaoder(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBuildingOptions([]);
        setUnitLaoder(false);
      });
  };
  const getBedDetailsForSingle = (
    tenant_id: any,
    unitId: any,
    tenant_type: any
  ) => {
    setBedroomLoader(true);
    return WebService.getAPI({
      action: `search/${tenant_id}/bed-dropdown/${unitId}?type=${tenant_type}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((bed: any) => ({
            value: bed?.id,
            label: bed?.value,
          }));
          setBedOptions(updatedOptions);
          // if (res?.list?.length === 1) {
          //   setServiceRequestDeatils((prev: any) => ({
          //     ...prev,
          //     bed_id: {
          //       value: res?.list[0]?.id,
          //       label: res?.list[0]?.value,
          //     },
          //   }));
          // }
        } else {
          setBedOptions([]);
        }
        setBedroomLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBedOptions([]);
        setBedroomLoader(false);
      });
  };
  const getBedDetailsForMulti = (tenant_id: any, unitId: any) => {
    setBedroomLoader(true);
    return WebService.getAPI({
      action: `search/${tenant_id}/building/${unitId}/bed?type=${serviceRequestDetails?.tenant_id?.type}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((bed: any) => ({
            value: bed?.id,
            label: bed?.value,
          }));
          setBedOptions(updatedOptions);
          // if (res?.list?.length === 1) {
          //   setServiceRequestDeatils((prev: any) => ({
          //     ...prev,
          //     bed_id: {
          //       value: res?.list[0]?.id,
          //       label: res?.list[0]?.value,
          //     },
          //   }));
          // }
        } else {
          setBedOptions([]);
        }
        setBedroomLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBedOptions([]);
        setBedroomLoader(false);
      });
  };
  const handleNext = () => {
    let flag = false;
    if (
      serviceRequestDetails?.property_id?.value === "" ||
      serviceRequestDetails?.tenant_id?.value === "" ||
      (serviceRequestDetails?.building_id?.value === "" &&
        serviceRequestDetails?.property_id?.type === "MULTIPLE_UNIT") ||
      serviceRequestDetails?.category_id?.value === "" ||
      serviceRequestDetails?.priority === "" ||
      (serviceRequestDetails?.room_id?.value === "" &&
        (serviceRequestDetails?.property_id?.booking_type === "PER_BED" ||
          serviceRequestDetails?.property_id?.booking_type ===
            "ENTIRE_ROOM")) ||
      serviceRequestDetails?.unit_id?.value === "" ||
      (serviceRequestDetails?.bed_id?.value === "" &&
        serviceRequestDetails?.property_id?.booking_type === "PER_BED")
    ) {
      flag = true;
    }

    if (flag) {
      setErrflag(true);
      return;
    } else {
      setErrflag(false);
    }

    let obj = {
      raised_by_id: serviceRequestDetails?.tenant_id?.value,
      property_id: serviceRequestDetails?.property_id?.value,
      building_id: serviceRequestDetails?.building_id?.value,
      unit_id: serviceRequestDetails?.unit_id?.value,
      floor_unit_id:
        serviceRequestDetails?.property_id?.type === "SINGLE_UNIT"
          ? null
          : serviceRequestDetails?.unit_id?.unit_id,
      room_id: serviceRequestDetails?.room_id?.value,
      bed_id: serviceRequestDetails?.bed_id?.value,
      category_id: serviceRequestDetails?.category_id?.custom_obj
        ? null
        : serviceRequestDetails?.category_id?.value,
      has_custom_value:
        serviceRequestDetails?.category_id?.has_custom_value ?? 0,
      custom_obj: serviceRequestDetails?.category_id?.custom_obj ?? null,
      priority: serviceRequestDetails?.priority,
      narration: serviceRequestDetails?.narration,
      property_type: serviceRequestDetails?.property_id?.type,
      type: serviceRequestDetails?.tenant_id?.type,
      booking_type: serviceRequestDetails?.property_id?.booking_type,
    };
    serviceUUID && window.location.href.includes("edit-")
      ? updateServicerequest(obj)
      : handleSaveServiceReq(obj);
  };

  const handleSaveServiceReq = (obj: any) => {
    WebService.addLoader("next");
    setPageLoader(true);
    return WebService.postAPI({
      action: `property/maintenance`,
      body: obj,
    })
      .then((res: any) => {
        props?.handleNext(res.uuid);
        WebService.removeLoader("next");
        setPageLoader(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        WebService.removeLoader("next");
        setPageLoader(false);
      });
  };
  const updateServicerequest = (obj: any) => {
    WebService.addLoader("next");
    setPageLoader(true);
    return WebService.putAPI({
      action: `property/maintenance/${serviceUUID}`,
      body: obj,
    })
      .then((res: any) => {
        props?.handleNext(res.uuid);
        WebService.removeLoader("next");
        setPageLoader(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        WebService.removeLoader("next");
        setPageLoader(false);
      });
  };
  const handleTenantSelection = (item: any) => {
    if (item.type === "TENANT") {
      setServiceRequestDeatils((prev: any) => ({
        ...prev,
        tenant_id: {
          value: item.value,
          label: item.label,
          type: item.type,
        },
        property_id: {
          value: item?.property?.id,
          label: item?.property?.name,
          type: item?.property?.property_type,
          booking_type: item?.booking_type,
        },
        unit_id: {
          value:
            item?.property?.property_type === "SINGLE_UNIT"
              ? item?.property?.unit?.id
              : item?.property?.unit?.id,
          label:
            item?.property?.property_type === "SINGLE_UNIT"
              ? item?.property?.unit?.master_value?.title
              : item?.property?.floor_unit?.room_no,
          unit_id:
            item?.property?.property_type === "SINGLE_UNIT"
              ? item?.property?.unit?.id
              : item?.property?.floor_unit?.id,
        },
        building_id: {
          value:
            item?.property?.property_type === "SINGLE_UNIT"
              ? ""
              : item?.property?.building?.id,
          label:
            item?.property?.property_type === "SINGLE_UNIT"
              ? ""
              : item?.property?.building?.building_name,
        },
        room_id: {
          value:
            item?.booking_type === "PER_BED" ||
            item?.booking_type === "ENTIRE_ROOM"
              ? item?.property?.room?.id
              : "",
          label:
            item?.booking_type === "PER_BED" ||
            item?.booking_type === "ENTIRE_ROOM"
              ? item?.property?.room?.master_value?.title
              : "",
        },

        bed_id: {
          value:
            item?.booking_type === "PER_BED" ? item?.property?.bed?.id : "",
          label:
            item?.booking_type === "PER_BED" ? item?.property?.bed?.value : "",
        },
      }));
      if (item?.property?.property_type === "MULTIPLE_UNIT") {
        getBuildingOptions(item?.value, item?.property?.id);
        getUnitDetailsForMulti(item?.value, item?.property?.building?.id);
      }

      if (
        item?.booking_type === "ENTIRE_ROOM" ||
        item?.booking_type === "PER_BED"
      ) {
        getroomOptions(item?.value, item?.property?.unit?.id, item.type);
        if (item?.property?.property_type === "MULTIPLE_UNIT") {
          getBedDetailsForMulti(item?.value, item?.property?.unit?.id);
        } else {
          getBedDetailsForSingle(
            item?.value,
            item?.property?.unit?.id,
            item.type
          );
        }
      }

      setPropetKey(Math.random());
      setBuildingKey(Math.random());
      setBedKey(Math.random());
      setUnitKey(Math.random());
    } else {
      setServiceRequestDeatils((prev: any) => ({
        ...prev,
        tenant_id: {
          value: item.value,
          label: item.label,
          type: item.type,
        },
      }));
    }
    if (item?.type) getPropertyOptions(item.value, item?.type);
  };
  return (
    <>
      <div className="layout-veritcle-menu">
        <InnerHeader
          pageTitle={
            window.location.href.includes("edit-")
              ? "Edit Request"
              : "Add Request"
          }
          backArrow={false}
          closeBtn={true}
          HelpBtn={true}
          closeUrl=""
        />
        <div className="app-body no-left-menu pb-lg-0 pb-5 mb-4">
          <div className="app-page page-properties property-detail pb-3 position-relative">
            {pageLoader ? <CompoLoader /> : ""}
            <h2 className="font-18 font-semibold mb-4">Raise Request</h2>
            <div className="form-style">
              <Row>
                <Col lg={12} className="mb-3">
                  <label className="form-label required">Raised By</label>
                  <Select
                    placeholder="Enter text to search & press ENTER"
                    values={
                      serviceRequestDetails?.tenant_id?.value === ""
                        ? []
                        : [
                            {
                              value: serviceRequestDetails?.tenant_id?.value,
                              label: serviceRequestDetails?.tenant_id?.label,
                            },
                          ]
                    }
                    options={tenantList}
                    loading={tenantLoader}
                    labelField="label"
                    valueField="value"
                    // getOptionLabel={(option:any) => option.label}
                    // getOptionValue={(option:any) => option.value}
                    handleKeyDownFn={(e: any) => {
                      if (
                        location?.state?.property_id !== "" &&
                        location?.state?.property_id !== undefined
                      ) {
                        if ((e?.state?.search).trim() !== "") {
                          if (e?.event?.key === `Enter`)
                            getTenantList(
                              e.state?.search,
                              location?.state?.property_id
                            );
                        } else {
                          setTenantList([]);
                        }
                      } else {
                        if ((e?.state?.search).trim() !== "") {
                          if (e?.event?.key === `Enter`)
                            getAllTenantList(e.state?.search);
                        } else {
                          setTenantList([]);
                        }
                      }
                    }}
                    onChange={(values) => {
                      return;
                    }}
                    itemRenderer={({ item, state, methods }) => {
                      return (
                        <>
                          <div
                            className="px-3 py-2"
                            onClick={() => {
                              handleTenantSelection(item);
                              state.values = [item];
                              methods.dropDown("close");
                            }}
                          >
                            <div className="d-flex gap-2">
                              <img
                                src={item.profile ?? ProfileImage}
                                width={34}
                                height={34}
                                className="object-fit-cover rounded"
                                alt=""
                              />
                              <div>
                                <p className="font-medium mb-0">
                                  {item.label}{" "}
                                </p>
                                <p className="mb-0 font-12">{item?.address}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  />
                  {serviceRequestDetails?.tenant_id?.value === "" && errFlag ? (
                    <Label title="Please select tenant" modeError={true} />
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg={6} className="mb-3">
                  <label className="form-label required">Property</label>
                  <Select
                    placeholder="Select Property"
                    key={propertyKey}
                    values={
                      serviceRequestDetails?.property_id.label === ""
                        ? []
                        : [serviceRequestDetails?.property_id]
                    }
                    loading={propertyOptionLoader}
                    options={propertyOptions}
                    labelField="label"
                    valueField="value"
                    onChange={(values: any) => {
                      if (values.length > 0) {
                        setServiceRequestDeatils((prev: any) => ({
                          ...prev,
                          property_id: values[0],
                        }));

                        if (values[0].type === "MULTIPLE_UNIT") {
                          getBuildingOptions(
                            serviceRequestDetails?.tenant_id?.value,
                            values[0].value
                          );
                        } else if (values[0].type === "SINGLE_UNIT") {
                          setServiceRequestDeatils((prev: any) => ({
                            ...prev,
                            building_id: { value: "", label: "" },
                            unit_id: { value: "", label: "" },
                          }));
                          setUnitKey(Math.random());
                          getUnitDetails_SingleUnit(
                            serviceRequestDetails?.tenant_id?.value,
                            values[0].value
                          );
                        }
                      }
                    }}
                  />
                  {serviceRequestDetails?.property_id?.value === "" &&
                  errFlag ? (
                    <Label title="Please select property" modeError={true} />
                  ) : (
                    ""
                  )}
                </Col>
                {serviceRequestDetails?.property_id?.type ===
                "MULTIPLE_UNIT" ? (
                  <>
                    <Col lg={6} className="mb-3">
                      <label className="form-label required">Building</label>
                      <Select
                        placeholder="Select Building"
                        values={
                          serviceRequestDetails?.building_id.label === ""
                            ? []
                            : [serviceRequestDetails?.building_id]
                        }
                        loading={buildingLoader}
                        options={buidingOptions}
                        key={buildingKey}
                        labelField="label"
                        valueField="value"
                        onChange={(values: any) => {
                          if (values.length > 0) {
                            setServiceRequestDeatils((prev: any) => ({
                              ...prev,
                              building_id: values[0],
                              unit_id: {
                                value: "",
                                label: "",
                                booking_type: "",
                                unit_id: "",
                              },
                              bed_id: { value: "", label: "" },
                            }));

                            if (
                              serviceRequestDetails?.property_id?.type ===
                              "MULTIPLE_UNIT"
                            ) {
                              getUnitDetailsForMulti(
                                serviceRequestDetails?.tenant_id?.value,
                                values[0].value
                              );
                            }

                            if (
                              serviceRequestDetails?.property_id?.type ===
                              "SINGLE_UNIT"
                            ) {
                              getUnitDetails_SingleUnit(
                                serviceRequestDetails?.tenant_id?.value,
                                values[0].value
                              );
                            }
                          }
                        }}
                      />
                    </Col>
                    {serviceRequestDetails?.building_id?.value === "" &&
                    errFlag ? (
                      <Label title="Please select building" modeError={true} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {serviceRequestDetails?.property_id?.type ===
                "MULTIPLE_UNIT" ? (
                  <>
                    <Col lg={6} className="mb-3">
                      <label className="form-label required">Unit Number</label>
                      <Select
                        placeholder="Select Unit"
                        key={unitKey}
                        values={
                          serviceRequestDetails?.unit_id.label === ""
                            ? []
                            : [serviceRequestDetails?.unit_id]
                        }
                        loading={unitLoader}
                        options={unitOptions}
                        labelField="label"
                        valueField="value"
                        onChange={(values: any) => {
                          if (values.length > 0) {
                            setServiceRequestDeatils((prev: any) => ({
                              ...prev,
                              unit_id: values[0],
                              bed_id: { value: "", label: "" },
                            }));
                            getroomOptions(
                              serviceRequestDetails?.tenant_id?.value,
                              values[0].value,
                              serviceRequestDetails?.tenant_id?.type
                            );
                          }
                        }}
                      />
                      {serviceRequestDetails?.unit_id?.value === "" &&
                      errFlag ? (
                        <Label title="Please select unit" modeError={true} />
                      ) : (
                        ""
                      )}
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {serviceRequestDetails?.property_id?.booking_type === "ENTIRE_ROOM" ||
                serviceRequestDetails?.property_id?.booking_type ===
                  "PER_BED" ? (
                  <>
                    <Col lg={6} className="mb-3">
                      <label className="form-label required">Room</label>
                      <Select
                        placeholder="Select Room"
                        // key={roomKey}
                        values={
                          serviceRequestDetails?.room_id.label === ""
                            ? []
                            : [serviceRequestDetails?.room_id]
                        }
                        loading={roomLoader}
                        options={roomOptions}
                        labelField="label"
                        valueField="value"
                        onChange={(values: any) => {
                          if (values.length > 0) {
                            setServiceRequestDeatils((prev: any) => ({
                              ...prev,
                              room_id: values[0],
                            }));
                            if (
                              serviceRequestDetails?.property_id?.type ===
                              "SINGLE_UNIT"
                            ) {
                              getBedDetailsForSingle(
                                serviceRequestDetails?.tenant_id?.value,
                                values[0].value,
                                serviceRequestDetails?.tenant_id?.type
                              );
                            }
                            if (
                              serviceRequestDetails?.property_id?.type ===
                              "MULTIPLE_UNIT"
                            ) {
                              getBedDetailsForMulti(
                                serviceRequestDetails?.tenant_id?.value,
                                values[0].value
                              );
                            }
                          }
                        }}
                      />
                      {serviceRequestDetails?.room_id?.value === "" &&
                      errFlag ? (
                        <Label title="Please select room" modeError={true} />
                      ) : (
                        ""
                      )}
                    </Col>
                  </>
                ) : (
                  ""
                )}

                {serviceRequestDetails?.property_id?.booking_type ===
                "PER_BED" ? (
                  <Col lg={6} className="mb-3">
                    <label className="form-label required">Bed Number</label>
                    <Select
                      placeholder="Select Bed"
                      values={
                        serviceRequestDetails?.bed_id.label === ""
                          ? []
                          : [serviceRequestDetails?.bed_id]
                      }
                      loading={bedroomLoader}
                      key={bedKey}
                      options={bedOptions}
                      labelField="label"
                      valueField="value"
                      onChange={(values: any) => {
                        if (values.length > 0) {
                          // setSelectBuildingErrMsg("");
                          setServiceRequestDeatils((prev: any) => ({
                            ...prev,
                            bed_id: values[0],
                          }));
                        }
                      }}
                    />
                    {serviceRequestDetails?.bed_id?.value === "" && errFlag ? (
                      <Label title="Please select bed" modeError={true} />
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={6} xs={6} className="mb-3">
                  <label className="form-label required">Category</label>
                  <Select
                    dropdownPosition="bottom"
                    loading={categortyLoader}
                    createNewLabel="Add New Category"
                    create
                    placeholder={placeHolder}
                    searchable={searchable}
                    autoFocus={autoFocus}
                    key={key}
                    onCreateNew={(value) => {
                      if (!Array.isArray(value)) {
                        let newOption = {
                          label: value.label,
                          value: value.value,
                          has_custom_value: 1,
                          custom_obj: { title: value.label },
                        };

                        if (newOption.has_custom_value === 1) {
                          let tempOptions = [...categoryOptions];
                          let insertIndex = tempOptions.length - 1;
                          tempOptions.splice(insertIndex, 0, newOption);
                          setCategoryOptions(tempOptions);
                          setServiceRequestDeatils((prev: any) => ({
                            ...prev,
                            category_id: newOption,
                          }));
                        }
                      }
                    }}
                    values={
                      serviceRequestDetails?.category_id.label === ""
                        ? []
                        : [serviceRequestDetails?.category_id]
                    }
                    options={categoryOptions}
                    labelField="label"
                    valueField="value"
                    onChange={(values: any) => {
                      if (
                        values[0]?.value === 0 &&
                        values[0]?.label === "+Add Custom"
                      ) {
                        setplaceHolder("Type here to add custom category");
                        setSearchable(true);
                        setAutoFocus(true);
                        setKey(Math.random());
                        return;
                      }
                      if (
                        values.length > 0 &&
                        values[0].has_custom_value === 0
                      ) {
                        setServiceRequestDeatils((prev: any) => ({
                          ...prev,
                          category_id: values[0],
                        }));
                      }
                      setAutoFocus(false);
                    }}
                  />
                  {serviceRequestDetails?.category_id?.value === "" &&
                  errFlag ? (
                    <Label title="Please select category" modeError={true} />
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg={6} xs={6} className="mb-3">
                  <label className="form-label required">Priority</label>
                  <Select
                    placeholder="Select Priority"
                    values={
                      serviceRequestDetails?.priority === ""
                        ? []
                        : [
                            {
                              value: serviceRequestDetails?.priority,
                              label: serviceRequestDetails?.priority,
                            },
                          ]
                    }
                    options={priorityOptions}
                    labelField="label"
                    valueField="value"
                    onChange={(values: any) => {
                      if (values.length > 0) {
                        // setSelectBuildingErrMsg("");
                        setServiceRequestDeatils((prev: any) => ({
                          ...prev,
                          priority: values[0].value,
                        }));
                      }
                    }}
                  />
                  {serviceRequestDetails?.priority === "" && errFlag ? (
                    <Label title="Please select priority" modeError={true} />
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg={12} className="mb-3">
                  <label className="form-label">Remarks</label>
                  <Form.Control
                    as="textarea"
                    className="h-auto"
                    value={serviceRequestDetails?.narration}
                    rows={4}
                    placeholder="Enter Remarks"
                    onChange={(e: any) => {
                      setServiceRequestDeatils((prev: any) => ({
                        ...prev,
                        narration: e.target.value,
                      }));
                    }}
                  ></Form.Control>
                </Col>
              </Row>
            </div>
          </div>
          <div className="form-footer">
            <ProgressBar now={30} />
            <div className="footer-wrap">
              <div className="text-end">
                <Button
                  onClick={() => handleNext()}
                  id="next"
                  className="btn btn-brand"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddServiceResquest1;
