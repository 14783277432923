import { FiChevronLeft } from "react-icons/fi";

interface PropData {
  title: string;
  backArrow?: boolean;
  tab?: string;
}

const PageTitle = (props: PropData) => {
  const goBack = () => {
    if (props?.tab && props?.tab !== "") {
      localStorage.setItem("property_detail_tab", props?.tab);
    } else {
      localStorage.setItem("property_detail_tab", "tab1");
      localStorage.setItem("unit_detail_tab", "tab1");
      localStorage.setItem("bed_detail_tab", "tab1");
    }
    window.history.back();
  };
  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        {props?.backArrow == true ? (
          <button className="btn btn-outline-light back-btn" onClick={goBack}>
            <FiChevronLeft size={16} />
          </button>
        ) : null}
        <h1 className="page-title">{props.title}</h1>
      </div>
    </>
  );
};
export default PageTitle;
