import HelperService from "./HelperService";
import MalePlaceHolder from "./../assets/images/default-avatar.jpg";
import FemalePlaceHolder from "./../assets/images/default-avatar_women.jpg";
const CommonFunction = {
  setCoverImageFromImageList(imageList: any) {
    let flag = 0;
    for (let i = 0; i < imageList.length; i++) {
      let image = imageList[i];
      if (image.has_covered_image === 1) {
        flag = 1;
        return image;
      }
    }
    if (flag === 0) {
      return imageList[0];
    }
  },

  checkFormEmptyFields(obj: any, excludedKeys: any = []) {
    let emptyKeyFlag = false;
    const excludedKeysSet = new Set(excludedKeys);

    Object.keys(obj).forEach((key) => {
      if (
        !excludedKeysSet.has(key) &&
        (obj[key] === undefined || !obj[key] || obj[key] === "")
      ) {
        console.log(key);
        emptyKeyFlag = true;
      }
    });

    console.log(emptyKeyFlag);

    return emptyKeyFlag;
  },
  getGallaryImages(imageList: any, type?: any) {
    let photoL: any = [];
    if (imageList.length > 0) {
      imageList.forEach((image: any) => {
        let obj = {
          original: type === "IMAGE" ? image.value : image.value,
          thumbnail: image.value,
          originalTitle: image?.title,
          thumbnailTitle: image?.title,
          description: CommonFunction.removeFileExtension(image?.title ?? ""),
        };
        photoL.push(obj);
      });
    }
    return photoL;
  },

  calculateTotalPayable(otherCharges: any, basic_rent: any) {
    let basiccharges = 0;
    let totalTax = 0;
    let totalCharges = 0;
    if (otherCharges?.length > 0) {
      otherCharges.forEach((element: any) => {
        basiccharges += element.basic_charge;
        totalTax += element.tax_price;
        totalCharges += element.total_charge;
      });
      totalCharges += basic_rent;
    } else {
      totalCharges = basic_rent;
    }
    return totalCharges;
  },

  inVoiceTotalCalculation(otherCharges: any) {
    let basicCharges = 0;
    let totalTax = 0;
    let discount = 0;
    let totalCharges = 0;
    if (otherCharges?.length > 0) {
      otherCharges.forEach((element: any) => {
        if (element?.type !== "DISCOUNT") {
          basicCharges += element.amount;
          totalTax += element.tax_price;
          totalCharges += element.total_amount;
        } else {
          discount += element.amount;
        }
      });
    }

    let obj = {
      sub_total: basicCharges,
      tax_total: totalTax,
      grand_total: totalCharges - discount,
    };

    return obj;
  },

  sortArrayBasedOnkey(array: any, key: any) {
    return array.sort(function (a: any, b: any) {
      var x = a[key];
      var y = b[key];
      // It compares these two values (x and y). If x is less than y, it returns -1 which means a should come before b. If x is greater than y,
      //  It returns 1 which means a should come after b. If they are equal, it returns 0 which means their order does not matter.
      return x < y ? -1 : x > y ? 1 : 0;
    });
  },

  decideClassForStatus(status1: any) {
    let status = status1?.toUpperCase();
    if (status === "OCCUPIED") return "badge badge-light1 mt-1";
    if (status === "PARTIAL_OCCUPIED") return "badge badge-warning mt-1";
    if (status === "VACANT") return "badge badge-green";
    if (status === "YES") return "status-badge badge bg-danger";
    else if (status === "ARCHIVED") return "status-badge badge badge-light";
  },
  decideStatus(status1: any) {
    let status = status1?.toUpperCase();
    if (status === "OCCUPIED") return "Occupied";
    if (status === "PARTIAL_OCCUPIED") return "Partially Occupied";
    if (status === "VACANT") return "Vacant";
    if (status === "YES") return "Draft";
    else if (status === "ARCHIVED") return "Archieved";
  },
  decideClassForLeadStatus(status: any) {
    if (status === "WARM") return "badge badge-warning mt-1 d-none d-lg-inline";
    if (status === "HOT") return "badge badge-danger  mt-1 d-none d-lg-inline";
    if (status === "COLD") return "badge badge-primary d-none d-lg-inline";
  },
  decideClassForInvoiceStatus(status: any) {
    if (status === "UNPAID") return "badge-danger badge bg-primary";
    else if (status === "PAID") return "badge-green badge bg-primary";
    else if (status === "PARTIALLY_PAID")
      return "badge-primary badge bg-primary";
  },
  decideClassForLeadStatus1(status: any) {
    if (status === "OPEN") return "badge-green col-cat";
    if (status === "CLOSED") return "badge-danger";
  },
  numberToWords(num: any) {
    const words = ["ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX"];
    return words[num];
  },
  WordToNumber(word: any) {
    const numberinWord = word?.split("_")[0];
    const word1 = word?.split("_")[1];
    const words = ["ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX"];
    const index = words?.indexOf(numberinWord) + 1;
    return HelperService.titleCase(word1) + " " + index;
  },
  getGenderPlaceHolder(gender: any) {
    if (gender === "MALE") return MalePlaceHolder;
    else if (gender === "FEMALE") return FemalePlaceHolder;
    else return MalePlaceHolder;
  },
  decideClassForBookingStatus(type: string, roomObj: any, bedObj: any) {
    let status = "";
    if (type === "ENTIRE_UNIT") {
      status = "OCCUPIED";
    } else if (type === "ENTIRE_ROOM") {
      status = roomObj?.property_status;
    } else if (type === "PER_BED") {
      status = bedObj?.property_status;
    }
    if (status === "OCCUPIED") return "badge-green";
  },

  decideStatusForBooking(type: string, roomObj: any, bedObj: any) {
    let status = "";
    if (type === "ENTIRE_UNIT") {
      status = "OCCUPIED";
    } else if (type === "ENTIRE_ROOM") {
      status = roomObj?.property_status;
    } else if (type === "PER_BED") {
      status = bedObj?.property_status;
    }
    return HelperService.toProperCase(status);
  },

  removeFileExtension(filename: string): string {
    if (!filename) return "";
    return filename.replace(/\.[^/.]+$/, "");
  },
};
export default CommonFunction;
